@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/mixins';
@import '../../sassStyles/global';



.myBanner{
    &.otherpagebanner{
        height: 80vh!important;
        .bnoverlay{
            .bnrtextwrapper{
                margin-top: 40vh;
            }
        }
        video{
            height: 80vh;
        }
    }
}
.contactbarcomp{
    padding:140px 0;

    .contactbarcard{
        width:100%;
        height: 180px;
        border: 1px solid #000;
        border-radius: 8px;
        .upperpart{
            height: 80px;
            
            position: relative;
            
            &::after{
                position: absolute;
                width: 265px;
                height: 1px;
                background-color: #000;
                left: 50%;
                transform: translateX(-50%);
                bottom:0;
                content:'';
            }
            .contentup{
                height: 32px;
                bottom:10px;
                position: absolute;
                
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                text-align: center;
                h4{
                    @include myfont(25px,700,$main_font);
                }
                img{
                    margin-top: -3px;
                }
            }
           
        }
        .lowerpart{
            .contentdown{
                text-align: center;
                p{
                    @include myfont(16px,400,$main_font);
                    margin-top: 5px;
                    a{
                        color:#000;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
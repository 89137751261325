@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/mixins';
@import '../../sassStyles/global';


.mobile{
    .pricingtable{
        height: 350px;
        overflow: scroll;
     margin-bottom: 120px;
     
     
    //  background-color: #f8f8f8;
    //  background: rgba(0, 94, 174, 0.3);
     
     
        @include commontablecss();
       table{
        border:1px solid #000;
        width:550px;
        thead{
            
            tr{
                position: unset!important;
                th{
                    border:1px solid #000;
                    &:first-child{
                        border-right: 1px solid rgba(0,0,0,0.7)!important;
                    }
                    @include myfont(25px,700,$main_font);
                    
                    &:nth-child(2){
                        background-color: #90b1dd;                    
                       
                       
                        
                    }
                    &:nth-child(3){
                        background-color: #6da1e0;
                    }
                    &:nth-child(4){
                        background-color: #4b83dd;
                    }
                }
                
            }
        }
        tbody{
            tr{
                &:last-child{
                    td{
                        &:nth-child(2){
                            border-bottom:1px solid rgba(0,0,0,0.7);
                        }
                        &:nth-child(3){
                            border-bottom:1px solid rgba(0,0,0,0.7);
                        }
                        &:nth-child(4){
                          border-bottom:1px solid rgba(0,0,0,0.7);
                        }
                    }
                }
                td{
                    @include myfont(14px,400,$main_font);
                    
                }
            }
        }

        tr{
            th,td{
                text-align: center;
                &:first-child{

                    width:40%!important;
                    text-align: start;
                    padding-left: 20px;
                }
                &:nth-child(2){
                    width: 20%;
                    border-left: 1px solid rgba(0,0,0,0.7);
                }
                &:nth-child(3){
                    width: 20%;
                }
                &:nth-child(4){
                    width: 20%;
                    border-right: 1px solid  rgba(0,0,0,0.7);
                }
            }
        }
       }
    }
}